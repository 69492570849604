import { useState, useEffect } from "react";
import Boton from "../boton/Boton";

export default function FormularioBoton(props) {
  const [botonClickeado, setBotonClickeado] = useState(false);

  const { config, loader, botonCallback } = props;

  function onClick() {
    setBotonClickeado(true);
    if (config.tipo === "submit") {
      return;
    }
    botonCallback(config.callback);
  }

  // Cuando apaga el loader, resetea el botón clickeado
  useEffect(() => {
    if (!loader) {
      setBotonClickeado(false);
    }
  }, [loader]);

  return (
    <Boton
      clases={"centrado " + config.clases}
      callback={onClick}
      loader={loader && botonClickeado}
      disabled={loader}
      tipo={config.tipo}
      confirmacion={config.confirmacion}
    >
      {config.etiqueta}
    </Boton>
  );
}
